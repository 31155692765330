<template>
  <div>
    <el-dialog title="重复导出检查" :visible.sync="show" width="900px" :before-close="handleClose">
      <el-table stripe :data="list" style="box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);overflow:auto" height="calc(100vh - 300px)">
        <el-table-column align="center" type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="submitOrgName" align="center" label="提交机构"></el-table-column>
        <el-table-column prop="contactPeopleName" align="center" label="客户" width="130"></el-table-column>
        <el-table-column prop="userPhone" align="center" label="使用者号码"></el-table-column>
        <el-table-column prop="contactPhone" align="center" label="银行预留号码"></el-table-column>
        <el-table-column prop="amount" align="center" label="面额（元）" width="90"></el-table-column>
        <template slot="empty">
          <label style="color:red;font-size:18px;">
            正在检查是否有重复数据，请等待！
          </label>
        </template>
      </el-table>
      <div style="margin-top:40px">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" v-debounce="exportList" v-if="showExportBtn">导出不重复数据</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getCookies, exportCommon } from '@/utils/utils'
import { compareExportExcel } from '@/api/issueList.js'
export default {
  props: ['show', 'type'],
  data() {
    return {
      sysId: sessionStorage.getItem('wlyn_sysId'),
      list: [],
      showExportBtn: false,
      exportObj: {
        isCompare: 1,
        // exportType: this.type
      }
    }
  },
  created() {
    this.exportObj.exportType = this.type
    this.compareIsRepeatData()
  },
  methods: {
    //判断是否有重复数据，如果没有则直接导出文件
    compareIsRepeatData() {
      let data = Object.assign(this.exportObj, this.$parent.search)
      data.submitOrg = data.submitOrg || getCookies('organizationId')
      
      delete data.pageSize
      delete data.pageNum
      // data.exportType = this.type
      let success = res => {
        this.list = res.data || []
        //如果没有重复则自动导出
        if (!this.list.length) {
          this.exportList()
        } else {
          this.showExportBtn = true;
        }
      }
      // console.log(Object.propotype.toString.call(data));

      compareExportExcel(data).then(success);
      // console.log(data);

    },
    handleClose() {
      this.$emit('update:show', false)
    },
    exportList() {
      let param = Object.assign(this.exportObj, this.$parent.search)
      param.submitOrg = param.submitOrg || getCookies('organizationId')
      param = { ...param, createBy: sessionStorage.getItem('wlyn_sysId') }
      exportCommon("/wlynIntegralWithdrawal/exportExcel", param);
      this.handleClose()
    },
  }
}
</script>

<style>
</style>