
<template>
  <div>
    <div class="top-btn">
      <el-button size="mini" type="primary" icon="el-icon-folder-opened" @click="exportClick(0)">导出</el-button>
      <el-button size="mini" type="primary" icon="el-icon-folder-opened" @click="exportClick(1)">导出T+3</el-button>
      <el-button size="mini" type="primary" icon="el-icon-folder-opened" @click="dialogImport = true">导入结果</el-button>
    </div>
    <el-form label-width="80px" inline size="mini">
      <el-form-item label="模糊查询">
        <el-input style="width:160px;" placeholder="请输入名称/手机号/卡号/身份证" v-model="search.search"></el-input>
      </el-form-item>
      <el-form-item label="发放状态">
        <el-select style="width:140px;" v-model="search.integralWithdrawalState" placeholder="选择状态">
          <el-option label="全部" value=""></el-option>
          <el-option label="未发放" value="0"></el-option>
          <el-option label="已发放" value="1"></el-option>
          <el-option label="已退回" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否超龄">
        <el-select style="width:120px;" v-model="search.isOverAge" placeholder="选择是否超龄">
          <el-option label="全部" value=""></el-option>
          <el-option label="否" value="0"></el-option>
          <el-option label="是" value="1"></el-option>
        </el-select>
      </el-form-item>
      <OrganizationSelect v-model="search.submitOrg" @reload="getList(1)"></OrganizationSelect>
      <el-form-item label="提交时间">
        <el-date-picker v-model="search.submitStartTime" type="datetime" placeholder="选择开始日期" value-format="yyyy-MM-dd HH:mm:ss" style="width:180px;"></el-date-picker> 至
        <el-date-picker v-model="search.submitEndTime" type="datetime" placeholder="选择结束日期" value-format="yyyy-MM-dd HH:mm:ss" style="width:180px;"></el-date-picker>
      </el-form-item>
      <el-form-item label="发放时间">
        <el-date-picker v-model="search.grantStartTime" type="datetime" placeholder="选择开始日期" value-format="yyyy-MM-dd HH:mm:ss" style="width:180px;"></el-date-picker> 至
        <el-date-picker v-model="search.grantEndTime" type="datetime" placeholder="选择结束日期" value-format="yyyy-MM-dd HH:mm:ss" style="width:180px;"></el-date-picker>
      </el-form-item>
      <el-form-item label=" ">
        <el-button type="primary" icon="el-icon-search" @click="searchList">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table stripe :data="list" style="box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);overflow:auto" height="calc(100vh - 290px)">
      <el-table-column align="center" type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="submitOrgName" align="center" label="提交机构"></el-table-column>
      <el-table-column prop="contactPeopleName" align="center" label="客户" width="130"></el-table-column>
      <el-table-column prop="userPhone" align="center" label="使用者号码"></el-table-column>
      <el-table-column prop="contactPhone" align="center" label="银行预留号码"></el-table-column>
      <el-table-column prop="amount" align="center" label="面额（元）" width="90"></el-table-column>
      <el-table-column prop="createTime" align="center" label="提交时间" width="150"></el-table-column>
      <el-table-column prop="isUse" align="center" label="状态" width="100">
        <template slot-scope="scope">
          <el-tag class="el-tag-style" size="mini" type="primary" disable-transitions v-if="scope.row.integralWithdrawalState == 0">未发放</el-tag>
          <el-tag class="el-tag-style" size="mini" type="success" disable-transitions v-if="scope.row.integralWithdrawalState == 1">已发放</el-tag>
          <el-tag class="el-tag-style" size="mini" type="warning" disable-transitions v-if="scope.row.integralWithdrawalState == 2">已退回</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="grantTime" align="center" label="发放/退回时间" width="150">
        <template slot-scope="scope">
          {{scope.row.integralWithdrawalState == 2?scope.row.returnTime:scope.row.grantTime}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="350">
        <template slot-scope="scope">
          <el-button type="text" icon="el-icon-view" style="color:#333" @click="editDetail(scope.row.id,1)">查看</el-button>
          <el-button type="text" icon="el-icon-remove-outline" @click="editDetail(scope.row.id,3)" v-if="scope.row.integralWithdrawalState == 0">发放</el-button>
          <el-button type="text" icon="el-icon-refresh-right" @click="editDetail(scope.row.id,4)" v-if="scope.row.integralWithdrawalState == 0">退回</el-button>
          <el-button type="text" icon="el-icon-edit" @click="editDetail(scope.row.id,2)" v-if="scope.row.integralWithdrawalState == 0">编辑</el-button>
          <el-button type="text" icon="el-icon-edit" @click="editDetail(scope.row.id,5)" v-if="scope.row.integralWithdrawalState == 2">编辑</el-button>
        </template>
      </el-table-column>

    </el-table>
    <div style="display:flex;width:100%">
      <p style="line-height:30px;width:200px">总数 : {{totalCount || 0}}</p>
      <el-pagination style="text-align:center;flex:1" layout="total,prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="pageSize" :current-page="pageNum" :total="total">
      </el-pagination>
    </div>
    <EditDetail v-if="isEditDialog" :isAddDialog.sync="isEditDialog" :editId.sync="editId" :editType.sync="editType"></EditDetail>
    <importDialog :isAddDialog.sync="dialogImport" v-if="dialogImport"></importDialog>
    <Export :show.sync="dialogExport" v-if="dialogExport" :type="exportType"></Export>
  </div>

</template>
<script>
import { getIntegralWithdrawalList, updateIntegralWithdrawal, countIntegralWithdrawalAmount } from '@/api/issueList'
import { getCookies, exportCommon } from '@/utils/utils'
import { baseURL } from '@/utils/http'

import OrganizationSelect from '@/components/OrganizationSelect.vue'
import EditDetail from './components/EditDetail.vue'
import importDialog from './components/importDialog.vue'
import Export from './components/Export.vue'

export default {

  components: {
    EditDetail, importDialog, OrganizationSelect, Export
  },
  data() {
    return {
      url: baseURL,
      batchType: 3,        //批次类型(0.核销码,1.实名发放)
      search: {
        integralWithdrawalState: '0',
        submitOrg: '',
        search: '',
        isOverAge: '',
        submitStartTime: '',
        submitEndTime: '',
        grantStartTime: '',
        grantEndTime: '',
      },
      editId: '',
      editType: '',            // 编辑状态 1是详情，2是编辑

      isEditDialogTitle: ['查看提现详情', '编辑提现详情', '发放确认'],
      isEditDialog: false,

      list: [],
      organizationList: [],
      total: 0,//分页总页数
      pageNum: 1,//分页页数
      pageSize: 10,//每页显示多少
      dialogImport: false,
      dialogExport: false,
      exportType: 1,
      totalCount: '',
      channelList: [
        { name: '身边云', id: 0 },
        { name: '嗨客', id: 1 },
        { name: '艾薪', id: 2 },
        { name: '合摩', id: 3 },
        { name: '小豆', id: 4 },
        { name: '孔朝', id: 5 },
        { name: '鑫路源', id: 6 },
        { name: '万友引利', id: 7 },
        { name: '北斗', id: 8 },
        { name: '中禧', id: 9 },
        { name: '广西微客(外部)', id: 10 },
        { name: '广西在线文化(外部)', id: 11 },
        { name: '其他', id: 12 }
      ],
    }
  },
  watch: {
    isEditDialog(val) {
      if (val == false)
        this.getList(1);
    },
    dialogImport(val) {
      if (val == false)
        this.getList(1);
    },
  },
  created() {
    // this.search.submitOrg = getCookies('organizationId') == 1 ? '' : getCookies('organizationId')
    // this.getList(1);
  },
  methods: {

    // type == 1 查看详情 type == 2 编辑
    editDetail(id, type) {
      this.editType = type;
      this.editId = id;
      this.isEditDialog = true;
    },
    searchList() {
      this.pageNum = 1;
      this.getList(1);
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },

    getList(t) {
      let param = {
        isPage: 1,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      Object.assign(param, this.search)
      if (t == 1) {
        countIntegralWithdrawalAmount(param).then(res => {
          this.totalCount = res.data
        })
      }
      getIntegralWithdrawalList(param)
        .then((response) => {
          if (response.code == 200) {
            this.total = response.data.total;
            this.list = response.data.records;
          }
        })
    },
    exportClick(type) {

      this.exportType = type
      this.dialogExport = true
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@/common/scss/base";
.el-tag-style {
  width: 60px;
  text-align: center;
}
.form-item-box {
  float: left;
  height: 46px;
}
.el-form-item {
  margin: 0;
}

.btn-blue-hover {
  margin: 0 6px;
  color: $hoverRed;
}
.btn-blue-hover:hover {
  color: $hoverYellow;
}

.btn-red-hover {
  margin: 0 6px;
  color: $hoverBlue;
}
.btn-red-hover:hover {
  color: $hoverYellow;
}
</style>