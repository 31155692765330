<template>
  <el-dialog title="导入结果" :visible.sync="isAddDialog" :close-on-click-modal="false" width="1200px" :before-close="dialogClose">
    <div style="width:100%;" v-loading="loading" element-loading-text="正在导入中...">
      <el-form style="text-align:left" label-width="120px" ref="form" :model="form" :rules="rules" inline size="small">
        <el-form-item label="标题" prop="title">
          <el-input v-model="form.title" style="width:200px" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item label="关联审批单">
          <el-input v-model="form.auditCode" style="width:200px" placeholder="请输入审批单号"></el-input>
        </el-form-item>
        <OrganizationSelect required :showAll="false" :isCheck="false" v-model="form.orgId" style="width:420px" @input="orgIdChange"></OrganizationSelect>
        <el-form-item label="渠道" prop="grantType">
          <el-select v-model="form.grantType" style="width:200px" placeholder="选择渠道">
            <el-option v-for="item in channelList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="下发时间" prop="grantTime">
          <el-date-picker v-model="form.grantTime" type="date" placeholder="选择下发时间" value-format="yyyy-MM-dd" style="width:200px;"></el-date-picker>
          <!-- <el-input v-model="form.grantTime" style="width:200px" oninput="value=value.replace(/[^\d-]/g,'')" placeholder="请输入下发时间"></el-input> -->
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.remark" style="width:200px" placeholder="请输入备注"></el-input>
        </el-form-item>
        <el-form-item label="Excel模板">
          <a style="margin:0px;display:block;width:200px" href="https://template.gongxingtech.com/提现-发放导入Excel.xlsx">下载模板</a>
        </el-form-item>
        <el-form-item label="导入模板">
          <el-upload :class="typeObj.total?'upload-demo':''" ref="upload" accept=".xls,.xlsx" :action="`${url}/wlynIntegralWithdrawal/importIntegralWithdrawal?orgId=${form.orgId}`" :before-remove="beforeRemove" :on-remove="handleRemove" :on-exceed="onExceed" :on-success="handleSuccess" :before-upload="beforeUpload" :on-progress="onProgress" :on-error="onError" :headers="headers" :limit="1">
            <el-button size="small" type="primary" v-if="!typeObj.total">点击上传</el-button>
            <span slot="tip" style="margin-left:20px" class="el-upload__tip" v-if="!typeObj.total">只允许上传Excel文件</span>
          </el-upload>
        </el-form-item>

        <p v-show="form.orgId" style="color:red">当前机构提现已下发 {{orgObj.grantIntegral |toThousands}} 元 ， 提现未下发 {{orgObj.notWithdrawalIntegral |toThousands}} 元</p>
        <el-row v-if="typeObj.total">
          <el-col :span="16" style="text-align:left">
            <span>本次导入共有{{typeObj.total}}人，其中已下发{{typeObj.grantCount}}人，需要退回{{typeObj.returnCount}}人 </span>
            <span v-if="typeObj.errorData && typeObj.errorData.length>0">，错误{{typeObj.errorData.length}}人， </span>
            <p>本次导入总金额 {{typeObj.totalAmount}} 元， 成功总额 {{typeObj.grantAmount}} 元，退回总额 {{typeObj.returnAmount}} 元， 错误总额 {{typeObj.errorAmount}} 元</p>
          </el-col>
          <el-col :span="4">
            <el-checkbox v-show="typeObj.errorData && typeObj.errorData.length" v-model="value1" @change="switchChange">显示错误数据</el-checkbox>
          </el-col>
          <el-col :span="4" style="text-align:right">
            <el-checkbox v-model="form.isSendSms">下发成功发送短信</el-checkbox>
          </el-col>
        </el-row>
      </el-form>

      <el-table border stripe :data="list" height="300px" size="mini">
        <el-table-column align="center" label="序号" width="90" type="index"></el-table-column>
        <el-table-column prop="contactPeopleName" align="center" label="姓名" width="100"></el-table-column>
        <el-table-column prop="contactPhone" align="center" label="手机号码" width="150"></el-table-column>
        <el-table-column prop="idCode" align="center" label="身份证" width="150"></el-table-column>
        <el-table-column prop="cardNumber" align="center" label="银行卡号" width="160"></el-table-column>
        <el-table-column prop="amount" align="center" label="金额" width="100"></el-table-column>
        <el-table-column prop="integralWithdrawalStateName" align="center" label="状态"></el-table-column>
        <el-table-column prop="grantSubject" align="center" label="发放主体"></el-table-column>
        <!-- <el-table-column prop="grantTypeName" align="center" label="渠道"></el-table-column> -->
        <el-table-column prop="remark" align="center" label="备注" show-overflow-tooltip></el-table-column>
      </el-table>
      <div style="margin-top:20px">
        <el-button type="primary" v-debounce="sureImport" :disabled="!typeObj.successData || !typeObj.successData.length || loading" :loading="loading">{{loading?'正在导入':'确认导入'}}</el-button>
        <el-button type="info" @click="dialogClose">关闭窗口</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>

import OrganizationSelect from '@/components/OrganizationSelect.vue'
import { confirmImport, countOrgIntegralTotal } from '@/api/issueList';
import { baseURL } from '@/utils/http';
import { getCookies } from '@/utils/utils'
export default {
  props: ['isAddDialog'],
  components: { OrganizationSelect },
  data() {
    return {
      loading: false,
      list: [],
      url: baseURL,
      headers: {
        'x-token': getCookies('x-token')
      },
      typeObj: {
        errorData: [],
        successData: []
      },
      form: {
        orgId: getCookies('organizationId'),
        isSendSms: false,
        title: '',
        auditCode: '',
        remark: '',
        grantType: '',
        grantTime: ''
      },
      channelList: [
        { name: '身边云', id: 0 },
        { name: '嗨客', id: 1 },
        { name: '艾薪', id: 2 },
        { name: '合摩', id: 3 },
        { name: '小豆', id: 4 },
        { name: '孔朝', id: 5 },
        { name: '鑫路源', id: 6 },
        { name: '万友引利', id: 7 },
        { name: '北斗', id: 8 },
        { name: '中禧', id: 9 },
        { name: '广西微客(外部)', id: 10 },
        { name: '广西在线文化(外部)', id: 11 },
        { name: '其他', id: 12 }
      ],
      value1: false,
      orgObj: {},

      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: ['change', 'blur'] },
        ],
        grantType: [
          { required: true, message: '请选择渠道', trigger: ['change', 'blur'] },
        ],
        grantTime: [
          { required: true, message: '请输入下发时间', trigger: ['change', 'blur'] },
        ],
      },
    }
  },
  created() {
    this.orgIdChange(this.form.orgId)
  },
  filters: {
    toThousands(num = 0) {
      let c = (num.toString().indexOf('.') !== -1) ? num.toLocaleString() : num.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
      return c
    }
  },
  methods: {
    orgIdChange() {
      if (!this.form.orgId) {
        this.orgObj = {}
        return
      }
      countOrgIntegralTotal({ orgId: this.form.orgId }).then(res => {
        this.orgObj = res.data || {}
      })
    },
    sureImport() {
      if (!this.$validate('form')) {
        return
      }
      if (!this.form.orgId) {
        this.$message.warning('请选择提交机构')
        return
      }
      if (this.typeObj.errorData && this.typeObj.errorData.length) {
        this.$message.warning('表单中有错误数据，请检查后重新导入')
        return
      }
      if (!this.typeObj.successData || !this.typeObj.successData.length) {
        this.$message.warning('没有正确的数据')
        return
      }
      this.$confirm('确认导入当前列表？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        let data = Object.assign({}, this.form)
        data.excelList = this.typeObj.successData
        data.fileUrl = this.typeObj.fileUrl
        data.totalAmount = this.typeObj.totalAmount
        data.grantAmount = this.typeObj.grantAmount
        data.returnAmount = this.typeObj.returnAmount
        data.errorAmount = this.typeObj.errorAmount
        confirmImport(data).then((response) => {
          this.loading = false
          setTimeout(() => {
            this.$parent.handleCurrentChange(1)
          }, 500)
          this.dialogClose();
          this.$message({
            type: response.code == 200 ? 'success' : 'error',
            message: response.msg || response.error_msg || response.message,
            duration: 1500
          });
        }).catch(err => {
          setTimeout(() => {
            this.$parent.handleCurrentChange(1)
          }, 500)
          this.dialogClose();
          this.loading = false
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    dialogClose() {
      this.$emit('update:isAddDialog', false)
    },
    switchChange() {
      this.list = this.value1 ? this.typeObj.errorData : this.typeObj.successData
      console.log(this.value1)
    },
    handleSuccess(e, file) {
      console.log(file)
      this.loading = false
      if (e.code == 200) {
        this.list = e.data.successData
        this.typeObj = e.data || {}
      } else {
        this.$refs['upload'].clearFiles()
      }
      this.$message({
        message: e.msg || e.error_msg || e.message || '上传失败',
        type: e.code == 200 ? 'success' : 'error'
      });
    },
    beforeUpload() {
      if (!this.form.orgId) {
        this.$message.warning('请选择提交机构')
        return false
      }
    },
    onProgress() {
      this.loading = true
    },
    onError() {
      this.loading = false
      this.$message.error('上传失败')
    },
    onExceed() {
      this.$message.error('最多只能上传一个文件')
    },

    beforeRemove(e) {
      return this.$confirm('确定删除该文件？')
    },
    handleRemove() {
      this.list = []
      this.typeObj = {}
    }
  }
}
</script>
<style scoped>
.upload-demo /deep/ .el-upload {
  display: none;
}
</style>
